// ==========================================================================
// Plyr Audios
// TODO: Create as class
// ==========================================================================

import controls from './controls';
import { dedupe } from './utils/arrays';
import {
  createElement,
  emptyElement,
  getAttributesFromSelector,
  insertAfter,
  removeElement,
  toggleClass,
} from './utils/elements';
import is from './utils/is';

const audios = {
  // Setup audios
  setup(providedHls = null) {
    // Inject the container
    if (!is.element(this.elements.audios)) {
      this.elements.audios = createElement('div', getAttributesFromSelector(this.config.selectors.audios));

      insertAfter(this.elements.audios, this.elements.wrapper);
    }

    const browserLanguages = navigator.languages || [navigator.language || navigator.userLanguage || 'en'];
    const languages = dedupe(browserLanguages.map((language) => language.split('-')[0]));
    let language = (this.audios.language || this.config.audios.language || 'auto').toLowerCase();

    // Use first browser language when language is 'auto'
    if (language === 'auto') {
      [language] = languages;
    }

    language = language.length !== 3 ? audios.correctLanguageCode.call(this, language) : language;

    this.hls = providedHls !== null ? providedHls : this.config.hls;
    setTimeout(() => {
      const list = this.hls == null ? this.media.audioTracks : this.hls.audioTracks; // null is iPhone buil-in hls
      if (this.hls == null) {
        this.media.audioTracks.onaddtrack = (event) => { audios.update.call(this, event) };
      }
      if (!list.length) {
        return;
      }
      Object.assign(this.audios, {
        language,
        languages,
        list,
      });

      const languageExists = Boolean(list.find((track) => track.lang === language));
      // Update language first time it matches, or if the previous matching track was removed
      if (languageExists) {
        audios.setLanguage.call(this, language);
      }

      // Fix audio for missing languages
      if (!languageExists) {
        let newLanguage = list[0].lang;
        this.audios.language = newLanguage;
        audios.setLanguage.call(this, newLanguage);
      }

      controls.setAudiosMenu.call(this);
    }, 0);
  },

  update(event) {
    let list = [];
    for (let i = 0; i < this.media.audioTracks.length; i += 1) {
      list[i] = { name: this.media.audioTracks[i].label, lang: this.media.audioTracks[i].language };
    }

    const browserLanguages = navigator.languages || [navigator.language || navigator.userLanguage || 'en'];
    const languages = dedupe(browserLanguages.map((language) => language.split('-')[0]));
    let language = (this.audios.language || this.config.audios.language || 'auto').toLowerCase();

    // Use first browser language when language is 'auto'
    if (language === 'auto') {
      [language] = languages;
    }

    language = language.length !== 3 ? audios.correctLanguageCode.call(this, language) : language;

    Object.assign(this.audios, {
      language,
      languages,
      list,
    });

    const languageExists = Boolean(list.find((track) => track.lang === language));

    // Update language first time it matches, or if the previous matching track was removed

    if (languageExists) {
      audios.setLanguage.call(this, language);
    }

    // Fix audio for missing languages
    if (!languageExists) {
      let newLanguage = list[0].lang;
      this.audios.language = newLanguage;
      audios.setLanguage.call(this, newLanguage);
    }

    controls.setAudiosMenu.call(this);
  },

  // Set audios by track index
  // Used internally for the currentTrack setter with the passive option forced to false
  set(index, passive = true) {
    const tracks = audios.getTracks.call(this);

    if (!is.number(index)) {
      this.debug.warn('Invalid audio argument', index);
      return;
    }

    if (!(index in tracks)) {
      this.debug.warn('Audio not found', index);
      return;
    }

    if (1) { // this.audios.currentAudio !== index
      this.audios.currentAudio = index;
      const track = tracks[index];
      const { lang } = track || {};

      if (this.hls == null) { // null is iPhone buil-in hls
        for (let i = 0; i < tracks.length; i++) {
          this.media.audioTracks[i].enabled = i === index;
        }
      } else {
        this.hls.audioTrack = index;
      }

      // Store reference to node for invalidation on remove
      this.audios.CurrentTrackNode = track;

      // Update settings menu
      controls.updateSetting.call(this, 'audios');

      // When passive, don't override user preferences
      if (!passive) {
        this.audios.language = lang;
      }
    }
  },

  correctLanguageCode(languageCode) {
    switch (languageCode) {
      case 'en':
        return 'eng';
        break;
      case 'es':
        return 'spa';
        break;
      case 'fr':
        return 'fra';
        break;
      case 'it':
        return 'ita';
        break;
      case 'de':
        return 'deu';
        break;
      case 'pt':
        return 'por';
        break;
      case 'sv':
        return 'swe';
      case 'pl':
        return 'pol';
      case 'nl':
        return 'nld';
        break;
      case 'id':
        return 'ind';
        break;
      case 'jp':
        return 'jpn';
        break;
      default:
        return 'eng';
        break;
    }
  },

  // Set audios by language
  // Used internally for the language setter with the passive option forced to false
  setLanguage(input, passive = true) {
    if (!is.string(input)) {
      this.debug.warn('Invalid language argument', input);
      return;
    }
    // Normalize

    const language = input.toLowerCase();
    this.audios.language = language;

    // Set currentTrack
    const tracks = audios.getTracks.call(this);
    const track = audios.findTrack.call(this, [language]);
    audios.set.call(this, tracks.indexOf(track), passive);
  },

  // Get current valid audio tracks
  // If update is false it will also ignore tracks without metadata
  // This is used to "freeze" the language options when audios.update is false
  getTracks() {
    // Handle media or textTracks missing or null
    const tracks = this.audios.list;

    return tracks;
  },

  // Match tracks based on languages and get the first
  findTrack(languages, force = false) {
    const tracks = audios.getTracks.call(this);
    let track;

    languages.every((language) => {
      track = tracks.find((t) => t.lang === language);
      return !track; // Break iteration if there is a match
    });

    // If no match is found but is required, get first
    return track || (force ? tracks[0] : undefined);
  },

  // Get the current track
  getCurrentTrack() {
    return audios.getTracks.call(this)[this.currentAudio];
  },

  // Get UI label for track
  getLabel(track) {
    if (is.empty(track)) {
      track = audios.getCurrentTrack.call(this);
    }

    if (!is.empty(track.name)) {
      return track.name === 'audio' ? 'English' : track.name;
    }

    if (!is.empty(track.lang)) {
      return track.lang.toUpperCase();
    }

    return 'English';
  },
};

export default audios;
